import React from 'react';
import PropTypes from 'prop-types';
import styles from "./PFSNavBar.module.css"

const PFSNavBar = ({ name, logout, children }) => {

  return (
    <nav
      data-testid='PfsNavBar'
      className={`position-fixed navbar navbar-dark bg-dark justify-content-between p-2 w-100 top-0 container-fluid ${styles.NavBar}`}
    >
      <h3 className='navbar-brand' data-testid="navBarName">{name}</h3>
      <div className='d-flex'>
        {children}
        <button onClick={() => logout()} className='btn btn-danger ms-2'>
          Logout
        </button>
      </div>
    </nav>
  );
};
PFSNavBar.propTypes = {
  name: PropTypes.string,
};

PFSNavBar.defaultProps = {
  name: '',
};
export default PFSNavBar;
