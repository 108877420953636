import axios from 'axios';
import { getToken } from '../lib/getToken'
import rollbar from './rollbar';

export const validateAuth = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/ValidateAuth`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return response.data?.success;
  } catch(e) {
    rollbar.debug(e);
    return false;
  }
};