import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PFSLogin from '../PFSLogin/PFSLogin';
import PFSPageNotFound from '../PFSPageNotFound/PFSPageNotFound';
import PFSBlogIndex from '../PFSBlogIndex/PFSBlogIndex';
import PFSBlogView from '../PFSBlogView/PFSBlogView'
import PFSBlogEdit from '../PFSBlogEdit/PFSBlogEdit';
import { Toaster } from 'react-hot-toast';
import AuthContext from '../../contexts/AuthContext'
import { useAuth } from '../../hooks/useLogin';

const PFSBody = () => {
  const { login, logout, isloggedIn } = useAuth();

  return (
    <div data-testid='PFSBody'>
      <AuthContext.Provider value={{login, logout, isloggedIn}}>
        <Toaster position='bottom-center' />
        <Routes>
          <Route path='/' element={<PFSBlogIndex />} />
          <Route path='/login' element={<PFSLogin />} />
          <Route path='/view_blog/:slug' element={<PFSBlogView />} />
          {['/add_blog', '/edit_blog/:slug'].map((path, index) => (
            <Route path={path} element={<PFSBlogEdit />} key={index} />
          ))}
          <Route path='/*' element={<PFSPageNotFound />} />
        </Routes>
      </AuthContext.Provider>
    </div>
  );
};

export default PFSBody;
