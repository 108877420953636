import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from '../../lib/loginRequest';
import toast from 'react-hot-toast';
import AuthContext from '../../contexts/AuthContext';
import { useRollbar } from '@rollbar/react';

const PFSLogin = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({ email: '', password: '' });
  const [loginDisabled, setLoginDisabled] = useState(false);
  const { login, isloggedIn } = useContext(AuthContext);
  const rollbar = useRollbar();

  useEffect(() => {
    isloggedIn && navigate('/');
    // eslint-disable-next-line
  }, [isloggedIn]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoginDisabled(true);
    try {
      const loggedInUser = await loginRequest(user.email, user.password);
      login(loggedInUser);
    } catch (error) {
      rollbar.debug(error);
      setLoginDisabled(false);
      toast.error('Invalid email or password');
    }
  };
  return (
    <div data-testid='PFSLogin' className='row justify-content-center'>
      <div className='col-md-7 col-lg-5'>
        <div className='p-4 p-md-5'>
          <h3 className='text-center mb-4'>Login</h3>
          <form data-testid='LoginForm' onSubmit={onSubmit}>
            <div className='form-group'>
              <input
                type='text'
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                className='form-control rounded-left mt-3'
                placeholder='Email'
                required
              />
            </div>
            <div className='form-group d-flex'>
              <input
                type='password'
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
                className='form-control rounded-left mt-3'
                placeholder='Password'
                required
              />
            </div>
            <div className='form-group mt-4'>
              <button
                disabled={loginDisabled}
                type='submit'
                className='form-control btn btn-primary rounded submit px-3 loading'
              >
              {loginDisabled ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  'Login'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PFSLogin;
