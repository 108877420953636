import React from 'react';

const PFSPageNotFound = () => (
  <div data-testid='PFSPageNotFound' className='row justify-content-center'>
    <div className='col-md-12 text-center'>
      <span className='display-1 d-block'>404</span>
      <div className='mb-4 lead'>
        The page you are looking for was not found.
      </div>
    </div>
  </div>
);


export default PFSPageNotFound;
