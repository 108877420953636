import { ErrorBoundary, Provider } from "@rollbar/react";
import PFSBody from "./components/PFSBody/PFSBody";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <PFSBody />
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
