import axios from 'axios';

export const loginRequest = async (email, password) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/login/${email}`,
    {
      email,
      password,
    }
  );
  const { token } = response.data;
  return { email, token };
};
