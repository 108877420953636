import React, { useCallback } from 'react';
import styles from './PFSBlog.module.css';
import PropTypes from 'prop-types';
import LoadingImage from "../LoadingImage/LoadingImage"
import { Link } from 'react-router-dom';

const PFSBlog = (props) => {
  const { id, slug, image, title, summary, publishedAt, deleteBlogPost } = props;
  const renderPublishedAt = useCallback(() => {
    if (publishedAt) {
      return new Date(publishedAt).toLocaleDateString('en-US', {
        timeZone: 'UTC',
      });
    } else {
      return '-';
    }
  }, [publishedAt]);

  return (
    <div
      className='col-xl-3 col-lg-4 col-md-6 col-sm-12 p-2'
      data-testid='PFSBlog'
    >
      <div className='card'>
        <LoadingImage
          image={image ?? ''}
          styleclass={styles.blogImage}
          altText='Blog'
        />
        <div className='card-body'>
          <h5 data-testid='blogtitle' className={styles.blogTitle}>
            {title}
          </h5>
          <p className='card-text'>{summary?.substring(0, 60) + '...'}</p>
          <p>
            <i className='bi bi-calendar2-fill p-1'></i>
            {renderPublishedAt()}
          </p>
          <div
            className={`${styles.iconContainer} d-flex justify-content-around`}
          >
            <Link
              to={`/view_blog/${slug}`}
              className='bi bi-eye-fill btn btn-success'
            ></Link>
            <Link
              to={`/edit_blog/${slug}`}
              className='bi bi-pencil-fill btn btn-primary'
            ></Link>
            <button
              data-testid='deleteButton'
              onClick={() => deleteBlogPost(id)}
              className='bi bi-trash-fill btn btn-danger'
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

PFSBlog.propTypes = {
  deleteBlogPost: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  publishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
};

PFSBlog.defaultProps = {
  id: undefined,
  image: undefined,
  title: undefined,
  summary: '',
  publishedAt: '',
  deleteBlogPost: undefined,
  slug: undefined
};

export default PFSBlog;
