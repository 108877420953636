import React from 'react';
import PropTypes from 'prop-types';

const PFSFormInput = ({label, type, name, value, onChange}) => (
  <div className="d-flex justify-content-around align-items-center pb-4">
    <h4 className="w-25">{label}:</h4>
    <input
      required
      data-testid={name + "Input"}
      type={type}
      className="form-control form-control-lg w-75"
      value={value}
      name={name}
      onChange={onChange}
    />
  </div>
);

PFSFormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

PFSFormInput.defaultProps = {
  label: undefined,
  type: 'text',
  name: undefined,
  value: undefined,
  onChange: undefined,
};

export default PFSFormInput;
