import React from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

import styling from './content.module.css'

const PFSFormContentEditor = ({ content, handleChange, editorClass }) => (
  <div className='d-flex justify-content-around align-items-center pb-4'>
    <h4 className='w-25'>Content:</h4>
    <div className={`w-75 ${editorClass}`}>
      <ReactQuill
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link', 'image', 'video'],
          ],
        }}
        theme="snow"
        value={content}
        className={`${styling.Editor}`}
        data-testid='reactQuillInput'
        onChange={handleChange}
      />
    </div>
  </div>
);

PFSFormContentEditor.propTypes = {
  content: PropTypes.string,
  handleChange: PropTypes.func,
  editorClass: PropTypes.string
};

PFSFormContentEditor.defaultProps = {
  content: '',
  handleChange: (e) => { },
  editorClass: ''
};

export default PFSFormContentEditor;
