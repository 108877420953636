// Vendors
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// CSS
import styles from './PFSBlogEdit.module.css';

// Lib
import { getSingleBlog } from '../../lib/getBlogsData';
import { deleteBlog } from '../../lib/deleteBlog';
import { postBlogData } from '../../lib/postBlog';

// Components
import PFSNavBar from '../PFSNavBar/PFSNavBar';
import PFSFormInput from '../PFSFormInput/PFSFormInput';
import PFSFormTextArea from '../PFSFormTextArea/PFSFormTextArea';
import PFSFormImageSelect from '../PFSFormImageSelect/PFSFormImageSelect';
import PFSFormContentEditor from '../PFSFormContentEditor/PFSFormContentEditor';
import AuthContext from '../../contexts/AuthContext';
import { useRollbar } from '@rollbar/react';

const PFSFormCancelButton = () => {
  return (
    <Link to='/' className='btn btn-warning m-2'>
      Cancel
    </Link>
  )
}

const PFSFormSaveButton = ({ label }) => {
  return (
    <button
      data-testid='saveBlogButton'
      type='submit'
      className='btn btn-success m-2'
    >
      {label}
    </button>
  )
}

const PFSFormDeleteButton = ({ handleClick }) => {
  return (
    <button
      type='button'
      onClick={handleClick}
      className='btn btn-danger m-2'
    >
      Delete
    </button>
  )
}

const renderDate = (date = new Date()) => {
  return new Date(date).toISOString().split('T')[0];
};

const PFSBlogEdit = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [blog, setBlog] = useState({
    title: '',
    bannerImage: '',
    content: '',
    summary: '',
    slug: '',
    author: '',
    image: null,
    file: null,
    publishedAt: ''
  });
  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState(null);
  const { logout } = useContext(AuthContext)
  const rollbar = useRollbar();

  const handleError = useCallback((error) => {
    if (error?.response?.status === 401) {
      toast.error(error.response.data?.message);
      logout();
    } else {
      rollbar.error(error);
      toast.error(error?.response?.data?.error)
    }
  }, [logout, rollbar]);

  const handleImageChange = (event) => {
    const reader = new FileReader();
    let thisFile = event.target.files[0];
    let name = thisFile.name;
    name = name.split(' ').join('');
    setBlog({ ...blog, image: name, file: thisFile });
    reader.readAsDataURL(thisFile);
    reader.onloadend = () => {
      const base64data = reader.result;
      setImage(base64data);
    };
  };

  const handleImageClear = useCallback(() => {
    setImage(null);
    setBlog(prevState => ({
      ...prevState,
      image: null,
      file: null,
      bannerImage: '',
    }));
  }, []);

  const saveBlog = useCallback(() => {
    postBlogData(blog)
      .then(() => {
        toast.success(`Blog is ${blog.id ? 'updated' : 'added'} Successfully`);
        navigate('/');
      })
      .catch((error) => {
        handleError(error);
      });
  }, [blog, handleError, navigate]);

  const deleteBlogPost = useCallback(() => {
    if (window.confirm('Are you sure you want to delete?')) {
      deleteBlog(blog.id)
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [blog, handleError, navigate]);

  const getBlog = useCallback(async () => {
    getSingleBlog(slug)
      .then((response) => {
        setBlog(response.data);
        setImage(response.data?.bannerImage);
      })
      .catch((e) => {
        rollbar.debug(e);
        navigate('/');
        toast.error(`The Blog you are trying to edit doesn't exists`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, rollbar, slug]);

  const handleInputChange = useCallback((e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  }, [blog]);

  const handlePublishedAtChange = useCallback((e) => {
    let revisedVal = blog.publishedAt
    try {
      revisedVal = renderDate(e.target.value);
    } catch (e) {
      console.error(e);
    }

    setBlog({ ...blog, [e.target.name]: revisedVal });
  }, [blog]);

  const handleContentChange = useCallback((value) => {
    setBlog({ ...blog, content: value });
  }, [blog])

  useEffect(() => {
    slug ? getBlog() : setLoading(false);
  }, [getBlog, slug]);

  return (
    <div data-testid='PfsBlogEdit'>
      <PFSNavBar logout={logout} name={`${slug ? 'Edit' : 'Add'} Blog`} />
      {loading ? (
        <div className='text-center mt-5 pt-5'>
          <div className='spinner-border m-5'></div>
          <div className='d-block sr-only m-5'>Loading...</div>
        </div>
      ) : (
        <div className='container mt-5 pt-5'>
          <form
            data-testid='BlogForm'
            onSubmit={(e) => {
              e.preventDefault();
              saveBlog();
            }}
          >
            <PFSFormInput
              label={'Title'}
              name={'title'}
              value={blog.title}
              onChange={handleInputChange}
            />
            <PFSFormInput
              label={'Slug'}
              name={'slug'}
              value={blog.slug}
              onChange={handleInputChange}
            />
            <PFSFormInput
              label={'Author'}
              name={'author'}
              value={blog.author}
              onChange={handleInputChange}
            />
            <PFSFormInput
              label={'Published At'}
              name={'publishedAt'}
              type={'date'}
              value={blog.publishedAt}
              onChange={handlePublishedAtChange}
            />
            <PFSFormTextArea
              label={'Summary'}
              name={'summary'}
              value={blog.summary}
              onChange={handleInputChange}
            />
            <PFSFormImageSelect
              label={'Image'}
              image={image}
              imageClass={styles.blogImage}
              name={'Blog'}
              handleImageChange={handleImageChange}
              handleImageClear={handleImageClear}
              buttonContainerClass={styles.icons}
            />
            <PFSFormContentEditor
              content={blog.content}
              handleChange={handleContentChange}
              editorClass={styles.editor}
            />
            <div
              className='d-flex justify-content-end mt-5 mb-5'
              id={styles.ButtonContainer}
            >
              <PFSFormCancelButton />
              <PFSFormSaveButton label={!slug ? 'Save' : 'Update'} />
              {blog?.id && <PFSFormDeleteButton handleClick={deleteBlogPost} />}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PFSBlogEdit;
