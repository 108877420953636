import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSingleBlog } from '../../lib/getBlogsData';
import { deleteBlog } from '../../lib/deleteBlog';
import { Link } from 'react-router-dom';
import PFSNavBar from '../PFSNavBar/PFSNavBar';
import styles from './PFSBlogView.module.css';
import LoadingImage from '../LoadingImage/LoadingImage';
import { toast } from 'react-hot-toast';
import AuthContext from '../../contexts/AuthContext';
import { useRollbar } from '@rollbar/react';

const PFSBlogView = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [blog, setBlog] = useState({});
  const [loading, setLoading] = useState(true);
  const rollbar = useRollbar();

  const { logout } = useContext(AuthContext)

  const deleteBlogPost = () => {
    if (window.confirm('Are you sure you want to delete?')) {
      deleteBlog(blog.id)
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error(error.response.data?.message);
            logout();
          } else {
            rollbar.error(error);
          }
        });
    }
  };
  const getBlog = useCallback(async () => {
    getSingleBlog(slug)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((e) => {
        navigate('/');
        rollbar.debug(e);
        toast.error(`The Blog you are trying to edit doesn't exists`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, rollbar, slug]);

  useEffect(() => {
    getBlog();
  }, [getBlog]);

  return (
    <div data-testid='PfsBlogView'>
      <PFSNavBar logout={logout} name='View Blog' />
      {loading ? (
        <div className='text-center mt-5 pt-5'>
          <div className='spinner-border m-5'></div>
          <div className='d-block sr-only m-5'>Loading...</div>
        </div>
      ) : (
        <div className='container mt-5 pt-5'>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Title:</h4>
            <h3 className='w-75'>{blog.title}</h3>
          </div>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Slug:</h4>
            <h4 className='w-75'>{blog.slug}</h4>
          </div>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Author:</h4>
            <h4 className='w-75'>{blog.author}</h4>
          </div>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Summary:</h4>
            <p className='w-75'>{blog.summary}</p>
          </div>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Image:</h4>
            <div className='w-75'>
              <LoadingImage
                image={blog.bannerImage ?? ''}
                styleclass={styles.blogImage}
                altText='Blog'
              />
            </div>
          </div>
          <div className='d-flex justify-content-around align-items-center pb-4'>
            <h4 className='w-25'>Content:</h4>
            <div
              className='mt-5 w-75'
              dangerouslySetInnerHTML={{ __html: blog.content }}
            ></div>
          </div>
          <div
            className='d-flex justify-content-end mt-5 mb-5'
            id={styles.ButtonContainer}
          >
            <Link to='/' className='btn btn-warning m-2'>
              Back
            </Link>
            <Link to={`/edit_blog/${slug}`} className='btn btn-success m-2'>
              Edit
            </Link>
            <button
              onClick={deleteBlogPost}
              className='btn btn-danger m-2 p-2'
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PFSBlogView;
