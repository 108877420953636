import axios from 'axios';
import { getToken } from './getToken';

export const deleteBlog = async (id) => {
  if (!process.env.REACT_APP_API_URL) {
    throw new Error('REACT_APP_API_URL environment variable must be set');
  }

  return axios.delete(`${process.env.REACT_APP_API_URL}/blogs?id=${id}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};