import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loadingGif from './loading.gif';
import emptyImage from './empty.png';

const LoadingImage = (props) => {
  const { image, styleClass, altText } = props;
  const [isImageLoading, setImageLoading] = useState(true);
  const [renderedImage, setRenderedImage] = useState(image);
  
  useEffect(() => {
    setRenderedImage(image);
  }, [image]);

  return (
    <>
      <img
        data-testid='ImageLoading'
        className={styleClass}
        src={loadingGif}
        hidden={!isImageLoading}
        alt='Loading...'
      />
      <img
        data-testid='ImageRendered'
        className={styleClass}
        onLoad={() => {
          setImageLoading(false);
        }}
        onError={() => {
          setImageLoading(false);
          setRenderedImage(emptyImage);
        }}
        hidden={isImageLoading}
        src={renderedImage || emptyImage}
        alt={altText}
      />
    </>
  );
};

LoadingImage.propTypes = {
  image: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  altText: PropTypes.string.isRequired,
};

LoadingImage.defaultProps = {
  image: undefined,
  styleClass: '',
  altText: undefined,
};

export default LoadingImage;
