import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import LoadingImage from '../LoadingImage/LoadingImage';

const PFSFormImageSelect = (props) => {
  const uploadImageField = useRef(null);
  const handleClick = useCallback(() => {
    uploadImageField.current?.click();
  }, []);

  return (
    <div className='d-flex justify-content-around align-items-center pb-4'>
      <h4 className='w-25'>{props.label}:</h4>
      <div className='w-75'>
        <LoadingImage
          image={props.image ?? ''}
          styleClass={props.imageClass}
          altText={props.name}
        />
        <input
          ref={uploadImageField}
          data-testid='uploadImageField'
          type='file'
          accept='image/png, image/jpeg'
          hidden
          onChange={props.handleImageChange}
        />
        <div className={props.buttonContainerClass}>
          <button
            type='button'
            data-testid='deleteIcon'
            onClick={props.handleImageClear}
            className='bi bi-trash btn btn-danger'
          ></button>
          <button
            type='button'
            data-testid='uploadIcon'
            onClick={handleClick}
            className='bi bi-upload btn btn-primary'
          ></button>
        </div>
      </div>
    </div>
  )
};

PFSFormImageSelect.propTypes = {
  label: PropTypes.string.isRequired,
  image: PropTypes.string,
  imageClass: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttonContainerClass: PropTypes.string.isRequired,
  handleImageChange: PropTypes.func,
  handleImageClear: PropTypes.func
};

PFSFormImageSelect.defaultProps = {
  label: undefined,
  image: undefined,
  imageClass: undefined,
  name: undefined,
  buttonContainerClass: undefined,
  handleImageChange: (e) => { },
  handleImageClear: () => { }
};

export default PFSFormImageSelect;
