import axios from 'axios';
import { getToken } from './getToken'

export const createBlogData = (blog) => {
  const BlogBody = new FormData();

  for (let key in blog) {
    BlogBody.append(key, blog[key]);
  }

  return BlogBody;
}

export const postBlogData = async (blog) => {
  const requestOptions = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  
  const blogData = createBlogData(blog);
  
  return axios.post(`${process.env.REACT_APP_API_URL}/blogs`, blogData, requestOptions);
};