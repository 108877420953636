import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateAuth } from '../lib/validateAuth'

export const useAuth = () => {
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(null);

  const login = (user) => {
    setLoggedInUser(user);
    localStorage.setItem('user', JSON.stringify(user));
  };

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    setLoggedInUser(null);
    navigate('/login');
  }, [navigate, setLoggedInUser]);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      logout();
      return
    }

    validateAuth()
      .then((loggedIn) => {
        if (loggedIn) {
          setLoggedInUser(JSON.parse(user));
        } else {
          logout()
        }
      })
  }, [logout]);

  return {
    isloggedIn: Boolean(loggedInUser),
    loggedInUser,
    login,
    logout,
  };
};