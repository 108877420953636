import React from 'react';
import PropTypes from 'prop-types';

const PFSFormTextArea = ({ label, name, value, onChange }) => (
  <div className='d-flex justify-content-around align-items-center pb-4'>
    <h4 className='w-25'>{label}:</h4>
    <textarea
      required
      data-testid={name + "Input"}
      rows={5}
      className='form-control form-control-lg w-75'
      value={value}
      name={name}
      onChange={onChange}
    />
  </div>
);

PFSFormTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

PFSFormTextArea.defaultProps = {
  label: undefined,
  name: undefined,
  value: undefined,
  onChange: undefined
};

export default PFSFormTextArea;
