import React, { useCallback, useContext, useEffect, useState } from 'react';
import PFSBlog from '../PFSBlog/PFSBlog';
import PFSNavBar from '../PFSNavBar/PFSNavBar';
import { getBlogsData } from '../../lib/getBlogsData';
import { deleteBlog } from '../../lib/deleteBlog';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import AuthContext from '../../contexts/AuthContext';
import { useRollbar } from '@rollbar/react';

const PFSSearchBar = ({ handleChange }) => {
  const handleChangeEvent = useCallback((e) => {
    handleChange(e.target.value);
  }, [handleChange]);

  return (
    <input
      className='form-control mr-sm-2'
      type='search'
      onChange={handleChangeEvent}
      placeholder='Search'
    />
  )
};

function sortByPublishedDateDescending(articleA, articleB) {
  const dateA = new Date(articleA.publishedAt || 0);
  const dateB = new Date(articleB.publishedAt || 0);

  return dateB.getTime() - dateA.getTime();
}

const PFSBlogIndex = () => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [fetchingBlogs, setFetchingBlogs] = useState(true);
  const { logout } = useContext(AuthContext);
  const rollbar = useRollbar();

  const getBlogs = useCallback(() => {
    getBlogsData().then((response) => {
      let responseData = response.data;
      try {
        responseData.sort(sortByPublishedDateDescending);
      } catch(e) {
        rollbar.error(e);
      }
      setFetchingBlogs(false);
      setBlogs(responseData);
      setFilteredBlogs(responseData);
    });
  }, [rollbar]);

  const handleSearchChange = useCallback((newVal) => {
    const newBlogs = blogs.filter((b) =>
      b.title.toLowerCase().includes(newVal.toLowerCase())
    );
    setFilteredBlogs(newBlogs);
  }, [blogs])

  const deleteBlogPost = async (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      deleteBlog(id)
        .then(() => {
          const newBlogs = blogs.filter((blog) => blog.id !== id);
          setBlogs(newBlogs);
          setFilteredBlogs(newBlogs);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error(error.response.data?.message);
            logout();
          } else {
            rollbar.error(error);
          }
        });
    }
  };
  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  return (
    <div data-testid='PfsBlogIndex'>
      <PFSNavBar logout={logout} name='Blogs'>
        <PFSSearchBar
          handleChange={handleSearchChange}
        />
      </PFSNavBar>
      {fetchingBlogs ? (
        <div className='text-center mt-5 pt-5'>
          <div className='spinner-border m-5'></div>
          <div className='d-block sr-only m-5'>Loading...</div>
        </div>
      ) : (
      <div className='container pt-5'>
        <div className='mt-5 d-flex justify-content-end'>
          <Link to='/add_blog' className='btn btn-success'>
            Add New Blog
          </Link>
        </div>
        <div className='row mt-5'>
          {filteredBlogs.map((blog) => (
            <PFSBlog
              key={blog.id}
              slug={blog.slug}
              id={blog.id}
              image={blog.bannerImage}
              publishedAt={blog.publishedAt}
              title={blog.title}
              summary={blog.summary}
              deleteBlogPost={deleteBlogPost}
            />
          ))}
        </div>
      </div>)}
    </div>
  );
};

PFSBlogIndex.propTypes = {};

PFSBlogIndex.defaultProps = {};

export default PFSBlogIndex;
